<i18n>
{
  "de": {
    "pageTitle": "Einstellungen",
    "generalSettings": "Allgemein",
    "generalSettingsInfo": "Allgemeine Einstellungen für das Portfolio",
    "energyCarrierSettings": "Energieträger & Emissionsfaktoren",
    "energyCarrierSettingsInfo": "Emissionsfaktoren, Primärenergiefaktoren und Nutzungen für Energieträger",
    "heatingTypesSettings": "Heizungstypen",
    "heatingTypesSettingsInfo": "Nutzungsgrade von Heizkesseln und Gütegrade von Wärmepumpen",
    "investmentCostsSettings": "Investitionskosten",
    "investmentCostsSettingsInfo": "Flächen- und leistungsbasierte Investitionskosten/Kostenkennzahlen von Komponenten der Gebäudehülle und -technik",
    "lifespansSettings": "Lebensdauern",
    "lifespansSettingsInfo": "Standardlebensdauer von Komponenten der Gebäudehülle und -technik",
    "renovationStandardsSettings": "Renovationsstandards",
    "renovationStandardsSettingsInfo": "Verfügbare Renovationsstandards für die Massnahmen",
    "targetsSettings": "Reduktionsziele",
    "targetsSettingsInfo": "Ziele für die Reduktion von Scope 1-2 Emissionen und Energiebedarf",
    "heatGridsSettings": "Wärmenetze",
    "heatGridsSettingsInfo": "Erstellen und bearbeiten von Wärmenetzen",
    "tagGroupsSettings": "Kategorisierungen",
    "tagGroupsSettingsInfo": "Kategorisierung von Liegenschaften"
  }
}
</i18n>

<template>
  <MainLayout :portfolio="portfolio" :ready="portfolioReady" class="portfolio-settings-page">
    <template #default>
      <PageTitle :title="$t('pageTitle')" :portfolio="portfolio" />
      <div>
        <router-link
          v-if="getPortfolioPermission('EDIT_PARAMS_SANE')"
          class="settings-link"
          :to="{ name: 'settingsGeneral', params: { portfolio_id: portfolio.id } }"
        >
          <div>
            <strong>{{ $t('generalSettings') }}</strong>
          </div>
          <div>{{ $t('generalSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsTargets', params: { portfolio_id: portfolio.id } }">
          <div>
            <strong>{{ $t('targetsSettings') }}</strong>
          </div>
          <div>{{ $t('targetsSettingsInfo') }}</div>
        </router-link>
        <router-link
          class="settings-link"
          :to="{ name: 'settingsEnergyCarriers', params: { portfolio_id: portfolio.id } }"
        >
          <div>
            <strong>{{ $t('energyCarrierSettings') }}</strong>
          </div>
          <div>{{ $t('energyCarrierSettingsInfo') }}</div>
        </router-link>
        <router-link
          class="settings-link"
          :to="{ name: 'settingsHeatingTypes', params: { portfolio_id: portfolio.id } }"
        >
          <div>
            <strong>{{ $t('heatingTypesSettings') }}</strong>
          </div>
          <div>{{ $t('heatingTypesSettingsInfo') }}</div>
        </router-link>
        <router-link
          class="settings-link"
          :to="{ name: 'settingsInvestmentCosts', params: { portfolio_id: portfolio.id } }"
        >
          <div>
            <strong>{{ $t('investmentCostsSettings') }}</strong>
          </div>
          <div>{{ $t('investmentCostsSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsLifespans', params: { portfolio_id: portfolio.id } }">
          <div>
            <strong>{{ $t('lifespansSettings') }}</strong>
          </div>
          <div>{{ $t('lifespansSettingsInfo') }}</div>
        </router-link>
        <router-link
          class="settings-link"
          :to="{ name: 'settingsRenovationStandards', params: { portfolio_id: portfolio.id } }"
        >
          <div>
            <strong>{{ $t('renovationStandardsSettings') }}</strong>
          </div>
          <div>{{ $t('renovationStandardsSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsHeatGrids', params: { portfolio_id: portfolio.id } }">
          <div>
            <strong>{{ $t('heatGridsSettings') }}</strong>
          </div>
          <div>{{ $t('heatGridsSettingsInfo') }}</div>
        </router-link>
        <router-link class="settings-link" :to="{ name: 'settingsTagGroups', params: { portfolio_id: portfolio.id } }">
          <div>
            <strong>{{ $t('tagGroupsSettings') }}</strong>
          </div>
          <div>{{ $t('tagGroupsSettingsInfo') }}</div>
        </router-link>
      </div>
    </template>
  </MainLayout>
</template>

<script>
import { mapGetters } from 'vuex'

import PageTitle from '@/components/shared/PageTitle.vue'
import MainLayout from '@/pages/layouts/MainLayout.vue'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin.vue'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin.vue'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin, // Provides: portfolio, portfolioLoading, portfolioReady
  ],

  components: {
    MainLayout,
    PageTitle,
  },

  computed: {
    ...mapGetters({
      getPortfolioPermission: 'permissions/getPortfolioPermission',
    }),
  },

  head() {
    return {
      title: () => {
        return { inner: this.$t('pageTitle') }
      },
    }
  },
}
</script>

<style>
.portfolio-settings-page .portfolio-name .button-section {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: start;
  align-items: baseline;
  gap: var(--spacing-m);
}

.portfolio-settings-page .settings-link {
  display: block;
  max-width: 500px;
  margin-top: var(--spacing-s);
  padding: var(--spacing-m);
  background-color: var(--background-color);
  border: var(--box-border);
  border-radius: var(--box-radius);
}
</style>
